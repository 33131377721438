/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/dist/css/bootstrap-grid.min.css';
@import './assets/styles/inputs.scss';
@import 'material-icons/iconfont/material-icons.css';

@font-face {
    font-family: Rubik-Ligth;
    src: url('./assets/fonts/rubik/Rubik-Regular.ttf') format("opentype");
}

* {
    font-family: Rubik-Ligth;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
