.input_nosubmit {
    width: 100%;
    display: block;
    padding: 9px 4px 9px 40px;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.input_nosubmit_white{
    display: block;
    border:none;
    border-radius: 5px;
    padding: 9px 4px 9px 40px;
    background: white url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

/* ________________________ CROSSED LABELS _____________________________*/
.crossed_label_containers{
    border: 1px solid #ddd;
    padding: 10px 25px;
    border-radius: 5px;
    position:relative;
    width:25%;
    min-width:175px;
    height:20px;
}

.crossed_labels{
    position: absolute;
    top: -9px;
    left: 20px;
    background-color: white;
    padding: 0px 4px;
    color: rgb(205 , 211 , 219);
    z-index: 1;
}

.border-red{
    border:3px solid #e74c3c;
    border-radius: 5px;
}

.border-grey{
    border: 1px solid #ddd;
}

.checkbox {
    $block: &;
  
    cursor: pointer;
    display: flex;
    align-items: center;
  
    &__input {
      position: absolute;
      width: 1.375em;
      height: 1.375em;
      opacity: 0;
      cursor: pointer;
  
      &:checked + #{$block}__icon .tick {
        stroke-dashoffset: 0;
      }
    }
  
    &__icon {
      width: 1.375em;
      height: 1.375em;
      flex-shrink: 0;
      overflow: visible;
  
      .tick {
        stroke-dasharray: 20px;
        stroke-dashoffset: 20px;
      }
    }
  
    &__label {
      margin-left: 0.5em;
    }
}






   
